import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FC, useEffect } from 'react';
import { getSession, getTwoFiles } from 'store/redux-toolkit/app/app-selector';
import { getFiles } from 'store/redux-toolkit/app/app-thunks';
import saveImg from '../../assets/images/save.png';

export const ListReports: FC = () => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(getSession);
  const files = useAppSelector(getTwoFiles);

  // const download = (name: string) => {
  //   session && dispatch(getFile({ session_id: session, filename: name }));
  // };

  useEffect(() => {
    session && dispatch(getFiles(session));
  }, []);

  return (
    <div className='list-wrapper'>
      <div className='download'>
        <div className='download__text'>Result</div>
        <a href={files?.result} rel='noreferrer noopener' target='_blank' download={files?.result}>
          <img className='download__img' src={saveImg} alt='download img' />
        </a>
      </div>
      <div className='download'>
        <div className='download__text'>Backup</div>
        <a href={files?.backup} rel='noreferrer noopener' target='_blank' download={files?.backup}>
          <img className='download__img' src={saveImg} alt='download img' />
        </a>
      </div>
    </div>
  );
};
