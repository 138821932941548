export enum LoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NEVER = 'NEVER',
  SUCCESS = 'SUCCESS',
}

export enum ERoutes {
  HOME = '/',
  LIST_REPORT = '/list-reports',
  LOGIN = '/login',
}
