import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { showError } from '../../../utils/showError';

import { ILoginSubmitValue } from '../../../components/Forms/LoginForm/types';

import { notificationContainer } from '../../../ui/notificationContainer';
import { clearErrors, resetAuthState } from './auth-slice';
import RequestService from '../../../services/http/interceptor/request-service';
import { endpoint } from '../../../services/endpoint';
import { ILogout } from './auth-types';

export const login = createAsyncThunk(
  'auth/login',
  async (payload: ILoginSubmitValue, thunkApi) => {
    try {
      const response = await RequestService.post(endpoint.auth.LOGIN, payload);
      // notificationContainer(String(`${t('Login.notif_success')} ${payload.email}`), 'success');
      thunkApi.dispatch(clearErrors());
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);

        return thunkApi.rejectWithValue(error?.response?.data.errors[0]);
      }
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async (payload: ILogout, thunkApi) => {
  const { token, refresh_token } = payload;
  try {
    const response = await RequestService.put(endpoint.auth.REFRESH, { refresh_token });
    await RequestService.delete(endpoint.auth.LOGOUT, { token: response.data.auth.access_token });
    thunkApi.dispatch(resetAuthState());
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const getRefresh = createAsyncThunk(
  'auth/getRefresh',
  async (payload: ILogout, thunkApi) => {
    const response = await RequestService.put(endpoint.auth.REFRESH, payload);
    try {
      thunkApi.dispatch(resetAuthState());
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
