import { ERoutes } from 'enum/types';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

const activeStyle = {
  textDecoration: 'underline',
};

const activeClassName = 'underline';

export const Tabs: FC = () => {
  return (
    <div className='nav'>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className='link'
        to={ERoutes.HOME}
      >
        Home
      </NavLink>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className='link'
        to={ERoutes.LIST_REPORT}
      >
        Download
      </NavLink>
    </div>
  );
};
