import { FC } from 'react';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import { LoginForm } from 'components/Forms/LoginForm';
import { login } from 'store/redux-toolkit/auth/auth-thunks';
import { useAppDispatch } from 'hooks/redux';

const Login: FC = () => {
  const dispatch = useAppDispatch();

  const handleLoginSubmit = (value: ILoginSubmitValue) => {
    dispatch(login(value));
  };

  return (
    <section className='authorization-section'>
      <div className='container'>
        <div className='authorization-box'>
          <div className='form'>
            <div className='form__header'>
              {/* <div className='form-logo'>
                <img src={logo} alt='logo' />
              </div> */}
              <p className='form-title form-title--center'>Login</p>
              <div className='form-subtitle'>
                <p>Please input your email and password</p>
              </div>
            </div>
            <LoginForm loginSubmit={handleLoginSubmit} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
