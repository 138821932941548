export const API_BASE_URL =
  window.location.hostname === 'localhost'
    ? `https://report.nimdalages.com/api`
    : `https://report.nimdalages.com/api`;

console.log(window.location.origin);

export const endpoint = {
  auth: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REFRESH: '/auth/token/refresh',
  },
  yotobe: {
    POST_TABLE_UPLOAD: '/table/upload',
    POST_TABLE_MODIFY: (session_id: string) => `/table/${session_id}/modify`,
    SEND_AWS: (session_id: string) => `/table/${session_id}/upload/json_excel`,
    GET_TABLE_FILES: (session_id: string) => `/table/${session_id}/download_links`,
    GET_TABLE_FILE: (session_id: string, filename: string) =>
      `/table/${session_id}/file/${filename}`,
  },
};
