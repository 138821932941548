import { IXlsx } from 'pages/EditReport/EditReport';
import { FC } from 'react';
import sortImg from '../../assets/images/remove.png';

interface IProps {
  item: IXlsx;
  index: number;
  onRemove: (value: string) => void;
}

export const TableRow: FC<IProps> = ({ item, index, onRemove }) => {
  return (
    <tr className='tr'>
      <td className='td'>
        <p className='td-value'>{index}</p>
        <img
          className='icon'
          onClick={() => onRemove(item.asset_id)}
          src={sortImg}
          alt='sort img'
        />
      </td>
      <td className='td'>
        <p className='td-value'>{item.asset_title}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.asset_labels}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.asset_type}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.isrc}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.upc}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.artist}</p>
      </td>

      <td className='td'>
        <p className='td-value'>{item.label}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.owned_views}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.youTube_auction}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.youTube_reserved}</p>
      </td>
      <td className='td'>
        <p className='td-value'>{item.youTube_split}</p>
      </td>
      <td className='td created-at'>
        <p className='td-value'>{item.partner_auction}</p>
      </td>
      <td className='td'>
        <p className='td-value '>{item.partner_reserved}</p>
      </td>
      <td className='td'>
        <p className='td-value '>{item.partner_revenue}</p>
      </td>
    </tr>
  );
};
