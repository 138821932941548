import { combineReducers } from '@reduxjs/toolkit';
import app from './redux-toolkit/app/app-slice';
import auth from './redux-toolkit/auth/auth-slice';

import { IAppState } from './redux-toolkit/app/app-types';
import { AuthState } from './redux-toolkit/auth/auth-types';

export interface RootState {
  app: IAppState;
  auth: AuthState;
}

export const rootReducer = combineReducers({
  app,
  auth,
});
