import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import './languages/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'ui/Loading';
import { useAppSelector } from 'hooks/redux';

export function App() {
  const { loading } = useAppSelector((state) => state.app);
  return (
    <Suspense fallback='Loading...'>
      <RouterProvider router={router} />
    </Suspense>
  );
}
