/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ChangeEvent, FC, useState } from 'react';
// import { postModify } from 'store/redux-toolkit/app/app-thunks';
import { DebounceInput } from 'react-debounce-input';
// import { getSession } from 'store/redux-toolkit/app/app-selector';
import { IInitialValues, IXlsx } from 'pages/EditReport/EditReport';
import { Select } from 'ui/Select/Select';

interface IProps {
  onFindLable: (event: ChangeEvent) => void;
  onPercent: (event: ChangeEvent) => void;
  handleInput: (event: ChangeEvent) => void;
  inputsValue: IInitialValues;
  onResetFillter: () => void;
  uniqArray?: Array<IXlsx> | null | undefined;
}

const dataSelectCharts = {
  project_wallet_number: 1,
  project_total_balances: 1,
  project_incoming_payments: 1,
  project_withdraw_payments: 1,
  project_invoice_payments: 1,
  project_deposit_by_coins: 1,
  project_withdrawal_by_coins: 1,
  project_invoice_by_coins: 1,
};

export const Header: FC<IProps> = ({
  onFindLable,
  onPercent,
  handleInput,
  inputsValue,
  onResetFillter,
  uniqArray,
}) => {
  const [chart, setChart] = useState<{ [key: string]: number }>(dataSelectCharts);
  const dispatch = useAppDispatch();

  const onModify = (event: ChangeEvent) => {
    const { value, name, id } = event.target as HTMLInputElement;

    switch (name) {
      case 'Owned Views':
        // dispatch(
        //   postModify({
        //     data: {
        //       field: name,
        //       ask: true,
        //       value: +value,
        //       action: 'decrease_by_percent',
        //     },
        //     params: { session_id: session },
        //   })
        // );

        onPercent(event);
        handleInput(event);

        break;
      case 'Label':
        // dispatch(
        //   postModify({
        //     data: {
        //       field: name,
        //       ask: true,
        //       value: value,
        //       action: id,
        //     },
        //     params: { session_id: session },
        //   })
        // );
        onFindLable(event);
        handleInput(event);
        break;
      default:
        // dispatch(
        //   postModify({
        //     data: {
        //       field: name,
        //       ask: true,
        //       value: +value,
        //       action: 'decrease_by_percent',
        //       round_value: false,
        //     },
        //     params: { session_id: session },
        //   })
        // );
        onPercent(event);
        handleInput(event);
    }
  };

  const handleSelects = (value: string, key?: string) => {
    const cloneVelue = { ...chart };
  };

  return (
    <form action='/' className='input-wrapper'>
      <div onClick={onResetFillter} className='button button-reset is-danger'>
        Reset
      </div>

      <DebounceInput
        className='input is-link'
        placeholder='Search'
        id='asset_title'
        name='Label'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.asset_title}
      />
      <DebounceInput
        className='input is-link'
        placeholder='Search'
        id='asset_labels'
        name='Label'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.asset_labels}
      />
      <DebounceInput
        className='input is-link'
        placeholder='Search'
        id='asset_type'
        name='Label'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.asset_type}
      />
      <DebounceInput
        className='input is-link'
        placeholder='Search'
        id='isrc'
        name='Label'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.isrc}
      />
      <DebounceInput
        className='input is-link'
        placeholder='Search'
        id='upc'
        name='Label'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.upc}
      />
      <DebounceInput
        className='input is-link'
        placeholder='Search'
        id='artist'
        name='Label'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.artist}
      />
      <div>
        <Select
          uniqArray={uniqArray}
          handleSelects={handleSelects}
          inputsValue={inputsValue}
          onModify={onModify}
        />
      </div>

      <DebounceInput
        name='Owned Views'
        className='input is-link'
        type='number'
        placeholder='Owned Views'
        id='owned_views'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.owned_views}
        max='100'
        min='1'
      />
      <DebounceInput
        className='input is-link'
        type='number'
        id='youTube_auction'
        placeholder='YouTube Revenue Split: Auction'
        name='YouTube Revenue Split : Auction'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.youTube_auction}
        max='100'
        min='1'
      />
      <DebounceInput
        className='input is-link'
        type='number'
        id='youTube_reserved'
        placeholder='YouTube Revenue Split: Reserved'
        name='YouTube Revenue Split : Reserved'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.youTube_reserved}
        max='100'
        min='1'
      />
      <DebounceInput
        className='input is-link'
        type='number'
        id='youTube_split'
        placeholder='YouTube Revenue Split'
        name='YouTube Revenue Split'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.youTube_split}
        max='100'
        min='1'
      />
      <DebounceInput
        className='input is-link'
        type='number'
        placeholder='Partner Revenue: Auction'
        name='Partner Revenue : Auction'
        id='partner_auction'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.partner_auction}
        max='100'
        min='1'
      />
      <DebounceInput
        className='input is-link'
        type='number'
        name='Partner Revenue : Reserved'
        id='partner_reserved'
        placeholder='Partner Revenue: Reserved'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.partner_reserved}
        min='1'
        max='100'
      />
      <DebounceInput
        name='Partner Revenue'
        className='input is-link'
        type='number'
        id='partner_revenue'
        placeholder='Partner Revenue'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.partner_revenue}
        max='100'
        min='1'
      />
    </form>
  );
};
