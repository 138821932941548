import AuthLayout from 'layouts/AuthLayout';
import { EditReport } from 'pages/EditReport/EditReport';
import { ListReports } from 'pages/ListReports/ListReports';
import { ERoutes } from '../enum/types';
import ErrorRoutes from './ErrorRoutes';

const PrivateRoutes = {
  path: ERoutes.HOME,
  element: <AuthLayout />,
  children: [
    {
      element: <ListReports />,
      path: ERoutes.LIST_REPORT,
    },
    {
      element: <EditReport />,
      path: ERoutes.HOME,
    },
    ErrorRoutes,
  ],
};

export default PrivateRoutes;
