import { IInitialValues } from 'pages/EditReport/EditReport';
import { ChangeEvent, FC } from 'react';
import { DebounceInput } from 'react-debounce-input';

interface IProps {
  onFindLable: (event: ChangeEvent) => void;
  handleInput: (event: ChangeEvent) => void;
  removeLabel: (event: ChangeEvent) => void;
  removeArtist: (event: ChangeEvent) => void;
  inputsValue: IInitialValues;
}

export const HeaderSearch: FC<IProps> = ({
  onFindLable,
  inputsValue,
  handleInput,
  removeLabel,
  removeArtist,
}) => {
  const onModify = (event: ChangeEvent) => {
    handleInput(event);
    onFindLable(event);
  };

  return (
    <form action='/' className='input-wrapper'>
      <div className='input is-link' style={{ opacity: 0 }} />
      <div className='input is-link' style={{ opacity: 0 }} />
      <div className='input is-link' style={{ opacity: 0 }} />
      <div className='input is-link' style={{ opacity: 0 }} />
      <div className='input is-link' style={{ opacity: 0 }} />
      <div className='input is-link' style={{ opacity: 0 }} />
      <DebounceInput
        className='input is-link'
        placeholder='Remove'
        id='artist'
        name='Artist'
        debounceTimeout={1000}
        onChange={removeArtist}
        value={inputsValue.artist}
      />
      <DebounceInput
        className='input is-link'
        placeholder='Remove'
        id='label'
        name='Label'
        debounceTimeout={1000}
        onChange={removeLabel}
        value={inputsValue.label_search}
      />
      <DebounceInput
        name='Owned Views'
        className='input is-link'
        type='text'
        placeholder='Search'
        id='owned_views_search'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.owned_views_search}
      />
      <DebounceInput
        className='input is-link'
        type='text'
        id='youTube_auction_search'
        placeholder='Search'
        name='YouTube Revenue Split : Auction'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.youTube_auction_search}
      />
      <DebounceInput
        className='input is-link'
        type='text'
        id='youTube_reserved_search'
        placeholder='Search'
        name='YouTube Revenue Split : Reserved'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.youTube_reserved_search}
      />
      <DebounceInput
        className='input is-link'
        type='text'
        id='youTube_split_search'
        placeholder='Search'
        name='YouTube Revenue Split'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.youTube_split_search}
      />
      <DebounceInput
        className='input is-link'
        type='text'
        placeholder='Search'
        name='Partner Revenue : Auction'
        id='partner_auction_search'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.partner_auction_search}
      />
      <DebounceInput
        className='input is-link'
        type='text'
        name='Partner Revenue : Reserved'
        id='partner_reserved_search'
        placeholder='Search'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.partner_reserved_search}
      />
      <DebounceInput
        name='Partner Revenue'
        className='input is-link'
        type='text'
        id='partner_revenue_search'
        placeholder='Search'
        debounceTimeout={1000}
        onChange={onModify}
        value={inputsValue.partner_revenue_search}
      />
    </form>
  );
};
