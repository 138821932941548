import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../enum/types';
import { AuthState, IUserResponse, IUserRegisterResponse } from './auth-types';

import { login, logout } from './auth-thunks';

const initialState: AuthState = {
  user: undefined,
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: {},
  isAuth: false,
  token: '',
  refresh_token: '',
  registerData: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = {};
    },
    clearSuccess: (state) => {
      state.success = '';
    },
    resetRegisterState: (state) => {
      state.registerData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(login.fulfilled, (state, { payload }: PayloadAction<IUserResponse>) => {
        state.isAuth = true;
        state.token = payload.auth.access_token;
        state.loadingState = LoadingStatus.LOADED;
        state.refresh_token = payload.auth.refresh_token;
      })
      .addCase(login.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.success = 'logout-confirmed';
      });
  },
});

export const { resetAuthState, clearErrors, resetRegisterState, clearSuccess } = authSlice.actions;
export default authSlice.reducer;
