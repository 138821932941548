import { FC } from 'react';
import { Oval } from 'react-loader-spinner';

export const Loader: FC = () => {
  return (
    <div id='on-remove' className='louder-wrapper-custom'>
      <div className='blur'></div>
      <div className='wrapper-oval'>
        <Oval secondaryColor='grey' color='#91d0ff' height={60} width={60} />
      </div>
    </div>
  );
};
