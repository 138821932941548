import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../enum/types';

import { getFile, getFiles, postModify, postUpload, sendAws } from './app-thunks';
import { IAppState, IFiles } from './app-types';

const initialState: IAppState = {
  table: null,
  files: null,
  file: null,
  session: '' || localStorage.getItem('session'),
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: null,
  loading: false,
  counter: null,
  lenghtTable: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
    clearTable: (state) => {
      state.table = null;
    },
    loadingState: (state) => {
      state.loading = !state.loading;
    },
    setStartLenghtTable: (state, { payload }: PayloadAction<number>) => {
      state.lenghtTable = payload;
    },
    setEndLenghtTable: (state, { payload }: PayloadAction<number>) => {
      state.counter = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUpload.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postUpload.fulfilled, (state, { payload }: PayloadAction<{ session: string }>) => {
        state.loadingState = LoadingStatus.LOADED;
        state.session = payload.session;
        localStorage.setItem('session', payload.session);
      })
      .addCase(postModify.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postModify.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.table = payload;
        state.loadingState = LoadingStatus.LOADED;
      })
      .addCase(postModify.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      })
      .addCase(getFiles.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getFiles.fulfilled, (state, { payload }: PayloadAction<IFiles>) => {
        state.files = payload;
        state.loadingState = LoadingStatus.LOADED;
      })
      .addCase(getFiles.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      })
      .addCase(getFile.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getFile.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.file = payload.data;
        state.loadingState = LoadingStatus.LOADED;
      })
      .addCase(getFile.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      })
      .addCase(sendAws.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(sendAws.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.LOADED;
      })
      .addCase(sendAws.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      });
  },
});

export const {
  clearErrors,
  clearSuccess,
  clearTable,
  loadingState,
  setStartLenghtTable,
  setEndLenghtTable,
} = appSlice.actions;
export default appSlice.reducer;
