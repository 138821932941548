import { IXlsx } from 'pages/EditReport/EditReport';
import { RootState } from 'store/rootReducer';
import { IAppState, IFiles } from './app-types';

export const selectAppState = (state: RootState): IAppState => state.app;
export const getSession = (state: RootState): string | null => selectAppState(state).session;
export const getTable = (state: RootState): Array<IXlsx> | null => selectAppState(state).table;
export const getCounter = (state: RootState): number | null => selectAppState(state).counter;
export const getLenghtTable = (state: RootState): number | null =>
  selectAppState(state).lenghtTable;
export const getTwoFiles = (state: RootState): IFiles | null => selectAppState(state).files;
