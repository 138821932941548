import { ERoutes } from 'enum/types';
import { useAppSelector } from 'hooks/redux';
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const styles = {
  width: '100%',
  'min-width': '100%',
};

const stylesMain = {
  padding: 0,
  'margin-top': 0,
};

const PublicLayout: FC = () => {
  const { isAuth } = useAppSelector((store) => store.auth);
  const location = useLocation();

  if (isAuth) return <Navigate to={ERoutes.HOME} state={{ from: location }} replace />;

  return (
    <div className='wrapper' style={styles}>
      <main className='main' style={stylesMain}>
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;
