import PublicLayout from 'layouts/PublicLayout';
import { EditReport } from 'pages/EditReport/EditReport';
import { ListReports } from 'pages/ListReports/ListReports';
import Login from 'pages/Login';
import { ERoutes } from '../enum/types';
import ErrorRoutes from './ErrorRoutes';

const PublicRoutes = {
  path: ERoutes.LOGIN,
  element: <PublicLayout />,
  children: [
    {
      element: <Login />,
      path: ERoutes.LOGIN,
    },
    ErrorRoutes,
  ],
};

export default PublicRoutes;
