/* eslint-disable no-inner-declarations */
import { Header } from 'components/Header/Header';
import { TableRow } from 'components/TableRow/TableRow';
import { FC, ChangeEvent, useState, useRef, useEffect } from 'react';
import { DropFile } from 'ui/DropFile/DropFile';
import * as XLSX from 'xlsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { postUpload, sendAws } from 'store/redux-toolkit/app/app-thunks';
import { getSession, getTable } from 'store/redux-toolkit/app/app-selector';
import {
  clearTable,
  loadingState,
  setEndLenghtTable,
  setStartLenghtTable,
} from 'store/redux-toolkit/app/app-slice';
import sortImg from '../../assets/images/up-down.png';
import { useNavigate } from 'react-router-dom';
import { objectTable } from 'CONST/objectTable';
import { Pagination } from 'ui/Pagination/Pagination';
import { Loader } from 'ui/Loading';
import { Buffer } from 'buffer';
import { notificationContainer } from 'ui/notificationContainer';
import { HeaderSearch } from 'components/HeaderSearch/HeaderSearch';

// @ts-ignore
window.Buffer = Buffer;

// import csvToJson from 'convert-csv-to-json';
export interface IXlsx {
  id: number;
  adjustment_type: string;
  asset_id: string;
  asset_title: string;
  asset_labels: string;
  asset_channel_id: string;
  asset_type: string;
  custom_id: string;
  isrc: string;
  upc: string;
  grid: string;
  artist: string;
  album: string;
  label: string;
  administer_publish_rights: string;
  owned_views: string;
  youTube_auction: number;
  youTube_reserved: number;
  youTube_served: number;
  youTube_partner_served: number;
  youTube_split: number;
  partner_auction: number;
  partner_reserved: number;
  partner_served: number;
  partner_partner_served: number;
  partner_revenue: number;
}

export const initialValues = {
  asset_title: '',
  asset_labels: '',
  asset_type: '',
  isrc: '',
  upc: '',
  artist: '',
  label: '',
  label_search: '',
  owned_views: '',
  owned_views_search: '',
  youTube_auction: '',
  youTube_reserved: '',
  youTube_split: '',
  youTube_auction_search: '',
  youTube_reserved_search: '',
  youTube_split_search: '',
  partner_auction: '',
  partner_reserved: '',
  partner_revenue: '',
  partner_auction_search: '',
  partner_reserved_search: '',
  partner_revenue_search: '',
};

export interface IInitialValues {
  asset_title: string;
  asset_labels: string;
  asset_type: string;
  isrc: string;
  upc: string;
  artist: string;
  label: string;
  label_search: string;
  owned_views: string;
  owned_views_search: string;
  youTube_auction: string;
  youTube_reserved: string;
  youTube_split: string;
  youTube_auction_search: string;
  youTube_reserved_search: string;
  youTube_split_search: string;
  partner_auction: string;
  partner_auction_search: string;
  partner_reserved: string;
  partner_reserved_search: string;
  partner_revenue: string;
  partner_revenue_search: string;
}

// eslint-disable-next-line no-useless-escape
const regEx = /[^\d\.]/g;

export const EditReport: FC = () => {
  const [file, setFile] = useState<any>(null);
  const [sendFile, setSendFile] = useState<any>(null);
  const [sortActive, setSortActive] = useState(false);
  const [xlsxArr, setXlsxArr] = useState<Array<IXlsx>>([]);
  const [xlsxArrClone, setXlsxArrClone] = useState<Array<IXlsx> | null>(null);
  const tableRef = useRef(null);
  const dispatch = useAppDispatch();
  const table = useAppSelector(getTable);
  const session = useAppSelector(getSession);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, settotalPages] = useState<number>(0);
  const { loading } = useAppSelector((state) => state.app);
  const [inputsValue, setInputsValue] = useState<IInitialValues>(initialValues);
  const [sumAmount, setSumEmount] = useState<number>(0);

  const handleInput = (event: ChangeEvent) => {
    const { value, id } = event.target as HTMLInputElement;

    setInputsValue({ ...inputsValue, [id]: value });
  };

  const createTable = (f: any) => {
    dispatch(clearTable());
    const reader = new FileReader();
    dispatch(loadingState());

    reader.onload = function (event) {
      const data = event?.target?.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const sum = 0;

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setStartLenghtTable(dataParse.length - 1);

      settotalPages(Math.ceil(dataParse.length / 100));

      const worker = new Worker(new URL('./worker.ts', import.meta.url));
      const obj = {
        dataParse,
      };
      worker.postMessage(obj);

      worker.onmessage = function (e) {
        dispatch(loadingState());
        setXlsxArr(e.data.newDataParse);
        setXlsxArrClone(e.data.newDataParse);

        setSumEmount(e.data.sum);
      };

      const formData = new FormData();
      formData.append('file', f);
      data && dispatch(postUpload(formData));
      // setTimeout(() => {
      //   dispatch(loadingState());
      // }, 1000);
    };
    reader.readAsBinaryString(f);
  };

  const createTableCsv = (f: any) => {
    const reader = new FileReader();
    dispatch(clearTable());
    dispatch(loadingState());

    reader.onloadend = function (event) {
      const data = event?.target?.result;
      // const readedData = XLSX.read(data, { type: 'binary' });
      // const wsname = readedData.SheetNames[0];
      // const sheet = readedData.Sheets[wsname];

      // const csv = [XLSX.utils.sheet_to_csv(sheet)];

      // const lines = csv.join().split('\n');
      // const headers = lines[0].split(',');

      const worker = new Worker(new URL('./worker.ts', import.meta.url));
      const obj = {
        data,
      };
      worker.postMessage(obj);

      worker.onmessage = function (e) {
        setStartLenghtTable(e.data.lines.length - 1);
        settotalPages(Math.ceil(e.data.lines.length / 100));
        dispatch(loadingState());
        setXlsxArr(e.data.result.slice(1));
        setXlsxArrClone(e.data.result.slice(1));
        setSumEmount(e.data.sum);
      };

      const formData = new FormData();
      formData.append('file', f);
      data && dispatch(postUpload(formData));
    };
    reader.readAsArrayBuffer(f);
  };

  const handleChange = (data: any) => {
    const f = data;
    setFile(data);
    data.type.toLowerCase().includes('csv') ? createTableCsv(f) : createTable(f);
  };

  const onSort = async () => {
    dispatch(loadingState());
    setSortActive(!sortActive);
    setXlsxArr(
      xlsxArr &&
        xlsxArr.sort(function (a, b) {
          return sortActive
            ? a.label.toLowerCase().localeCompare(b.label)
            : b.label.toLowerCase().localeCompare(a.label);
        })
    );
    dispatch(loadingState());

    // setSortActive(!sortActive);

    // dispatch(
    //   postModify({
    //     data: {
    //       field: 'Label',
    //       ask: sortActive,
    //       value: 0,
    //       action: 'sort',
    //     },
    //     params: { session_id: session },
    //   })
    // );
  };

  const onSortNumber = async (key: string) => {
    dispatch(loadingState());
    setSortActive(!sortActive);
    setXlsxArr(
      xlsxArr &&
        xlsxArr.sort(function (a, b) {
          // @ts-ignore
          return sortActive ? a[key] - b[key] : b[key] - a[key];
        })
    );
    dispatch(loadingState());

    // setSortActive(!sortActive);

    // dispatch(
    //   postModify({
    //     data: {
    //       field: 'Label',
    //       ask: sortActive,
    //       value: 0,
    //       action: 'sort',
    //     },
    //     params: { session_id: session },
    //   })
    // );
  };

  const iterableObject = (el: IXlsx) => {
    for (const key in inputsValue) {
      // @ts-ignore
      if (inputsValue[key]) {
        // @ts-ignore
        if (!el[key]) return false;
        // @ts-ignore
        return Object.values(inputsValue).includes(el[key]);
      }
    }
  };

  const removeLabel = (event: ChangeEvent) => {
    const { value, id } = event.target as HTMLInputElement;

    if (xlsxArrClone) {
      const result: Array<IXlsx> = xlsxArrClone.filter((label) => {
        if (!label.label.length) return label;
        return !value
          .trim()
          .split(',')
          .some(
            (string) =>
              string.toLowerCase().trim() ===
              // @ts-ignore
              String(label.label).toLowerCase().trim()
          );
      });

      const resultNew = result.filter((el) => iterableObject(el));
      const xlsxArrCloneNew = xlsxArrClone.filter((el) => iterableObject(el));

      let xlsxArrNew: Array<IXlsx>;

      if (value) {
        xlsxArrNew = resultNew.length ? resultNew : result;
      } else {
        xlsxArrNew =
          xlsxArrCloneNew.length &&
          Object.values(inputsValue).filter((el) => el.length).length - 1 > 0
            ? xlsxArrCloneNew
            : xlsxArrClone;
      }

      let sum: number = 0;

      xlsxArrNew.forEach((xlsx) => {
        if (+xlsx.partner_revenue) sum = sum + +xlsx.partner_revenue;
      });

      setSumEmount(sum);

      setXlsxArr(xlsxArrNew);

      settotalPages(Math.ceil(xlsxArrNew.length / 100));
    }
  };

  const removeArtist = (event: ChangeEvent) => {
    const { value, id } = event.target as HTMLInputElement;

    if (xlsxArrClone) {
      const result: Array<IXlsx> = xlsxArrClone.filter((artist) => {
        if (!artist.artist.length) return artist;
        return !value
          .trim()
          .split(',')
          .some(
            (string) =>
              string.toLowerCase().trim() ===
              // @ts-ignore
              String(artist.artist).toLowerCase().trim()
          );
      });

      const resultNew = result.filter((el) => iterableObject(el));
      const xlsxArrCloneNew = xlsxArrClone.filter((el) => iterableObject(el));

      let xlsxArrNew: Array<IXlsx>;

      if (value) {
        xlsxArrNew = resultNew.length ? resultNew : result;
      } else {
        xlsxArrNew =
          xlsxArrCloneNew.length &&
          Object.values(inputsValue).filter((el) => el.length).length - 1 > 0
            ? xlsxArrCloneNew
            : xlsxArrClone;
      }

      let sum: number = 0;

      xlsxArrNew.forEach((xlsx) => {
        if (+xlsx.partner_revenue) sum = sum + +xlsx.partner_revenue;
      });

      setSumEmount(sum);

      setXlsxArr(xlsxArrNew);

      settotalPages(Math.ceil(xlsxArrNew.length / 100));
    }
  };

  const onFindLable = (event: ChangeEvent) => {
    const { value, id } = event.target as HTMLInputElement;
    dispatch(loadingState());
    setPage(1);
    const searchArr = value
      .toLowerCase()
      .split(',')
      .map((e) => e.trim());
    if (xlsxArrClone) {
      const result: Array<IXlsx> = xlsxArrClone.filter((label) => {
        return (
          // @ts-ignore
          searchArr.indexOf(String(label[id.replace('_search', '')]).toLowerCase()) !== -1
        );
      });

      const resultNew = result.filter((el) => iterableObject(el));
      const xlsxArrCloneNew = xlsxArrClone.filter((el) => iterableObject(el));

      let xlsxArrNew: Array<IXlsx>;

      if (value) {
        xlsxArrNew = resultNew.length ? resultNew : result;
      } else {
        xlsxArrNew =
          xlsxArrCloneNew.length &&
          Object.values(inputsValue).filter((el) => el.length).length - 1 > 0
            ? xlsxArrCloneNew
            : xlsxArrClone;
      }
      let sum: number = 0;

      xlsxArrNew.forEach((xlsx) => {
        if (+xlsx.partner_revenue) sum = sum + +xlsx.partner_revenue;
      });

      setSumEmount(sum);
      setXlsxArr(xlsxArrNew);

      settotalPages(Math.ceil(xlsxArrNew.length / 100));
    }

    setTimeout(() => {
      dispatch(loadingState());
    }, 1000);
  };

  const onPercent = async (event: ChangeEvent) => {
    const { value, id }: { value: string; id: string } = event.target as HTMLInputElement;
    setSumEmount(0);
    if (+value > 100) {
      notificationContainer('The number must not be greater than 100', 'info');
      return;
    }
    if (+value < 0) {
      notificationContainer('The number must be greater than or equal to zero', 'info');
      return;
    }

    dispatch(loadingState());

    // if (xlsxArr) return;

    if (xlsxArrClone && typeof +value === 'number') {
      let sum: number = 0;
      const result = await xlsxArr.map((xlsx) => {
        //@ts-ignore
        const percent = xlsx[id] - xlsx[id] * (+value / 100);
        return { ...xlsx, [id]: id === 'owned_views' ? percent.toFixed() : percent };
      });

      const newFile = value
        ? result
        : xlsxArr &&
          xlsxArr.map((xlsx, index) => {
            //@ts-ignore
            return { ...xlsx, [id]: xlsxArrClone[index][id] };
          });

      if (value) {
        result.forEach((xlsx) => {
          if (+xlsx.partner_revenue) sum = sum + +xlsx.partner_revenue;
        });
        setSumEmount(sum);
      } else {
        newFile.forEach((xlsx) => {
          if (+xlsx.partner_revenue) sum = sum + +xlsx.partner_revenue;
        });
        setSumEmount(sum);
      }

      setXlsxArr(newFile);
    }

    setTimeout(() => {
      dispatch(loadingState());
    }, 1000);

    // xlsxArr &&
    //   setXlsxArr(
    //     xlsxArr.map((label) => {
    //       const result = {
    //         ...label,
    //         owned_views: Number(label.owned_views) - Number(value),
    //       };
    //       return result as unknown as IXlsx;
    //     })
    //   );
  };

  // const downloadExcel = (data: any) => {
  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //   //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  //   console.log(XLSX);
  //   XLSX.writeXLSX(workbook, 'DataSheet.xlsx');
  // };

  const onSubmitToAws = () => {
    const newData = xlsxArr.map((item) => {
      return {
        'Adjustment Type': item.adjustment_type || 0,
        'Asset ID': item.asset_id || 0,
        'Asset Title': item.asset_title || 0,
        'Asset Labels': item.asset_labels || 0,
        'Asset Channel ID': item.asset_channel_id || 0,
        'Asset Type': item.asset_type || 0,
        'Custom ID': item.custom_id || 0,
        ISRC: item.isrc || 0,
        UPC: item.upc || 0,
        GRid: item.grid || 0,
        Artist: item.artist || 0,
        Album: item.album || 0,
        Label: item.label || ' ',
        'Administer Publish Rights': item.administer_publish_rights || 0,
        'Owned Views': item.owned_views || 0,
        'YouTube Revenue Split : Auction': item.youTube_auction || 0,
        'YouTube Revenue Split : Reserved': item.youTube_reserved,
        'YouTube Revenue Split : Partner Sold YouTube Served': item.youTube_served || 0,
        'YouTube Revenue Split : Partner Sold Partner Served': item.youTube_partner_served,
        'YouTube Revenue Split': item.youTube_split || 0,
        'Partner Revenue : Auction': item.partner_auction || 0,
        'Partner Revenue : Reserved': item.partner_reserved || 0,
        'Partner Revenue : Partner Sold YouTube Served': item.partner_served || 0,
        'Partner Revenue : Partner Sold Partner Served': item.partner_partner_served || 0,
        'Partner Revenue': item.partner_revenue || 0,
      };
    });
    // const objJsonStr = JSON.stringify(newData);
    // const objJsonB64 = Buffer.from(objJsonStr).toString('base64');
    // const formData = new FormData();
    // formData.append('file', file);
    setFile(null);
    newData && dispatch(sendAws({ file: { data: newData }, session_id: session, navigate }));
  };

  // const onCsvToJson = (data: any) => {
  //   let json = csvToJson.getJsonFromCsv(data);
  // }

  const handlePageClick = (selected: number) => {
    setPage(selected);
  };

  const onRemove = (value: string) => {
    dispatch(loadingState());
    setXlsxArr(
      xlsxArr.filter((item) => {
        if (item.asset_id === value) setSumEmount(sumAmount - +item.partner_revenue);
        return item.asset_id !== value;
      })
    );
    xlsxArrClone &&
      setXlsxArrClone(
        xlsxArrClone.filter((item) => {
          if (item.asset_id === value) setSumEmount(sumAmount - +item.partner_revenue);
          return item.asset_id !== value;
        })
      );
    dispatch(loadingState());
  };

  const onResetFillter = () => {
    setInputsValue(initialValues);
    xlsxArrClone && setXlsxArr(xlsxArrClone);
    xlsxArrClone && settotalPages(Math.ceil(xlsxArrClone.length / 100));
  };

  const webworker = () => {
    dispatch(loadingState());

    const worker = new Worker(new URL('ui/DropFile/DropFile', import.meta.url));
    worker.postMessage(null);

    worker.onmessage = function (e) {
      dispatch(loadingState());
    };
  };

  const PER_PAGE = 100;
  const offset = page * PER_PAGE;

  return (
    <div className='table-block table-block--mt-24'>
      <div className='table-inner'>
        <HeaderSearch
          handleInput={handleInput}
          inputsValue={inputsValue}
          onFindLable={onFindLable}
          removeLabel={removeLabel}
          removeArtist={removeArtist}
        />
        <Header
          onResetFillter={onResetFillter}
          handleInput={handleInput}
          inputsValue={inputsValue}
          onFindLable={onFindLable}
          onPercent={onPercent}
        />

        <table ref={tableRef} className='table table--transactions-crypto'>
          <tbody className='table-header'>
            <tr className='tr'>
              <th className='td cursor'>
                <div onClick={onSort} className='td-name'>
                  <p>№</p>
                </div>
              </th>
              <th className='td cursor'>
                <div className='td-name'>
                  <p>Asset Title</p>
                </div>
              </th>
              <th className='td cursor'>
                <div className='td-name'>
                  <p>Asset Labels</p>
                </div>
              </th>
              <th className='td cursor'>
                <div className='td-name'>
                  <p>Asset Type</p>
                </div>
              </th>
              <th className='td cursor'>
                <div className='td-name'>
                  <p>ISRC</p>
                </div>
              </th>
              <th className='td cursor'>
                <div className='td-name'>
                  <p>UPC</p>
                </div>
              </th>
              <th className='td cursor'>
                <div className='td-name'>
                  <p>Artist</p>
                </div>
              </th>

              <th className='td cursor'>
                <div onClick={onSort} className='td-name'>
                  <p>Label</p>
                  <img src={sortImg} alt='sort img' />
                </div>
              </th>
              <th className='td cursor'>
                <div onClick={() => onSortNumber('owned_views')} className='td-name'>
                  <p>Owned Views</p>
                  <img src={sortImg} alt='sort img' />
                </div>
              </th>
              <th className='td'>
                <div className='td-name'>
                  <p>YouTube Revenue Split: Auction</p>
                </div>
              </th>
              <th className='td'>
                <div className='td-name'>
                  <p> YouTube Revenue Split: Reserved</p>
                </div>
              </th>
              <th className='td'>
                <div className='td-name'>
                  <p>YouTube Revenue Split</p>
                </div>
              </th>
              <th className='td'>
                <div onClick={() => onSortNumber('partner_auction')} className='td-name'>
                  <p>Partner Revenue: Auction</p>
                  <img src={sortImg} alt='sort img' />
                </div>
              </th>
              <th className='td'>
                <div onClick={() => onSortNumber('partner_reserved')} className='td-name'>
                  <p>Partner Revenue: Reserved</p>
                  <img src={sortImg} alt='sort img' />
                </div>
              </th>
              <th className='td'>
                <div className='td-name'>
                  <p>Partner Revenue</p>
                </div>
              </th>
            </tr>
          </tbody>

          {xlsxArr.slice(offset - 100, offset)?.map((item: IXlsx, i) => (
            <TableRow
              key={item.label + i}
              item={item as IXlsx}
              index={i + 1 + (offset === 100 ? 0 : offset === 200 ? offset - 100 : offset - 100)}
              onRemove={onRemove}
            />
          ))}
          <div className='sum'>&#8721; {sumAmount}</div>
        </table>
        {Number(xlsxArr?.length) > 100 ? (
          <Pagination pageCount={totalPages} onPageChange={handlePageClick} forcePage={page} />
        ) : null}
        <div className='wrapper-btn btn'>
          <DropFile file={file} handleChange={handleChange} />
          <button
            disabled={!(xlsxArr && session)}
            onClick={onSubmitToAws}
            className='button is-success'
          >
            Save
          </button>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};
