import { Tabs } from 'components/Tabs/Tabs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { logout } from 'store/redux-toolkit/auth/auth-thunks';
import logoutImg from '../../assets/images/logout.png';

const AuthLayout = () => {
  const { isAuth } = useAppSelector((store) => store.auth);
  const { token, refresh_token } = useAppSelector((store) => store.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();

  if (!isAuth) return <Navigate to='/login' state={{ from: location }} replace />;

  const onLogout = () => {
    dispatch(logout({ token, refresh_token }));
  };

  return (
    <div className='wrapper'>
      {isAuth && <img onClick={onLogout} className='logout' src={logoutImg} alt='logout icon' />}
      <h1 className='title'>Youtube report view</h1>
      <main className='main'>
        <Tabs />
        <Outlet />
      </main>
      {/* Footer */}
    </div>
  );
};

export default AuthLayout;
