import axios, { AxiosRequestConfig, Method } from 'axios';
import { API_BASE_URL } from 'services/endpoint';
import onError from './onError';

class RequestService {
  get = (
    url: string,
    params?: object,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('GET', url, null, isAuthRequired, contentType, params);
  };

  getDownload = (
    url: string,
    params?: object,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createDownloadRequest('GET', url, null, isAuthRequired, contentType, params);
  };

  post = (
    url: string,
    body: any,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('POST', url, body, isAuthRequired, contentType);
  };

  put = (
    url: string,
    body?: any,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('PUT', url, body, isAuthRequired, contentType);
  };

  delete = (
    url: string,
    body?: any,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('DELETE', url, body, isAuthRequired, contentType);
  };
}

const createRequest = (
  method: Method,
  url: string,
  body: any,
  isAuthRequired: boolean,
  contentType: string,
  params?: object
) => {
  const config: AxiosRequestConfig = {
    method: method,
    url: API_BASE_URL + url,
    data: body,
    params,
    // @ts-ignore
    headers: setHeader(isAuthRequired, contentType),
  };

  return axios(config);
  // .then((response) => response.data)
  // .catch((error: IAxiosError) => {
  //   store.dispatch(resetAuthState());
  // });
};

const createDownloadRequest = (
  method: Method,
  url: string,
  body: any,
  isAuthRequired: boolean,
  contentType: string,
  params?: object
) => {
  const config: AxiosRequestConfig = {
    method: method,
    url: API_BASE_URL + url,
    data: body,
    responseType: 'blob',
    params,
    // @ts-ignore
    headers: setHeader(isAuthRequired, contentType),
  };

  return axios(config);
  // .then((response) => response.data)
  // .catch((error: IAxiosError) => {
  //   store.dispatch(resetAuthState());
  // });
};

const setHeader = (isAuthRequired: boolean, contentType: string): void => {
  if (isAuthRequired) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${
      localStorage.getItem('token') || undefined
    }`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  axios.defaults.headers.common['Content-Type'] = contentType;
};

axios.interceptors.response.use((response) => response, onError);

export default new RequestService();
