import { useSelect } from 'hooks/useSelect';
import { IInitialValues, IXlsx } from 'pages/EditReport/EditReport';
import { ChangeEvent, FC } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useLocation } from 'react-router';
// import { showCharts, showChartsProvider } from 'services/constants/analytics';

interface IProps {
  uniqArray: Array<IXlsx> | null | undefined;
  handleSelects: (value: string, key?: string) => void;
  onModify: (event: ChangeEvent) => void;
  inputsValue: IInitialValues;
}

export const Select: FC<IProps> = ({ uniqArray, handleSelects, onModify, inputsValue }) => {
  const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect({});
  const { pathname } = useLocation();
  // const charts = pathname.includes('merchant') ? showCharts : showChartsProvider;

  const onSelect = (value: string) => {
    // handleSelects(uniqArray[value] ? '0' : '1', value);
    // setOpen(false);
  };

  const onReplace = (value: string) => {
    return value.toLowerCase().replaceAll(' ', '_');
  };

  return (
    // <div className='select-block'>
    //   <div
    //     className={`select select--type-checkbox select--show-chart ${
    //       open && uniqArray?.length ? 'active' : ''
    //     }`}
    //   >
    <DebounceInput
      className='input is-link'
      placeholder='Search'
      id='label'
      name='Label'
      debounceTimeout={1000}
      onChange={onModify}
      onClick={toggleOpen}
      value={inputsValue.label}
    />
    //     <div className='select__drop select__drop--width-340' ref={dropRef}>
    //       <div className='select__drop-scroll'>
    //         <div className='select__drop-item'>
    //           <ul>
    //             {uniqArray?.map((company, index) => (
    //               <li key={company.label + index}>
    //                 <div className='checkbox checkbox--mb-0 '>
    //                   <label className='checkbox__label'>
    //                     <input
    //                       type='checkbox'
    //                       className='hidden'
    //                       onChange={() => onSelect(onReplace(company.label))}
    //                       // checked={!!chart[onReplace(chartName)]}
    //                     />
    //                     <span className='checkbox__item'>
    //                       <span className='checkbox__item-icon'>
    //                         <svg
    //                           width='14'
    //                           height='11'
    //                           viewBox='0 0 14 11'
    //                           fill='none'
    //                           xmlns='http://www.w3.org/2000/svg'
    //                         >
    //                           <path
    //                             d='M5 10.42L0 5.42L1.41 4.01L5 7.59L12.59 0L14 1.42L5 10.42Z'
    //                             fill='#9996BE'
    //                           />
    //                         </svg>
    //                       </span>
    //                     </span>
    //                     <span className='checkbox__text'>{company.label}</span>
    //                   </label>
    //                 </div>
    //               </li>
    //             ))}
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
