import { FC, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useSelector } from 'react-redux';
import ErrorMsg from 'components/ErrorMsg';
import { Link } from 'react-router-dom';
import { ILoginForm, ILoginSubmitValue } from './types';
import { loginSchema } from 'form-validations/login-validation';
import { SvgShowPass } from 'ui/Svg/SvgLogin';

export const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
  const [isShowPass, setIsShowPass] = useState(false);

  const handlePassDisplay = (): void => {
    setIsShowPass(!isShowPass);
  };

  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <div className='authorization-form__body'>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={(values: ILoginSubmitValue, { setSubmitting }) => {
          loginSubmit(values);
          setSubmitting(false);
        }}
        validateOnBlur
        enableReinitialize
      >
        {({ isSubmitting, isValid, values, errors }) => {
          return (
            <Form>
              <div className='form__body'>
                <div className='form-group'>
                  <div className=''>
                    <div className=''>
                      <Field
                        className='input is-info'
                        type='email'
                        name='email'
                        required
                        placeholder='Email address'
                      />
                    </div>
                    {errors?.email ? <ErrorMsg msg={errors.email} /> : null}
                  </div>
                  <div className=''>
                    <div className=''>
                      <Field
                        className='input is-info'
                        title='Password'
                        type={isShowPass ? 'text' : 'password'}
                        name='password'
                        required
                        placeholder='Password'
                      />
                    </div>
                    {errors?.password ? <ErrorMsg msg={errors.password} /> : null}
                  </div>
                  {/* <div className='save-password'>
                    <Link to='/' className='link link--big link--fw-600'>
                      Reset password
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className='form-footer'>
                <button
                  disabled={!(isValid && values.email && values.password) || isSubmitting}
                  type='submit'
                  className='button button--long button is-link'
                >
                  Log In
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
