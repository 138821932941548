import { IAxiosError } from './types';

const onError = (error: IAxiosError) => {
  const status = error.response ? error.response.status : null;
  const errorResponse: any = error.config;

  // if (status === 401 && !errorResponse._retry) {
  //   store.dispatch(resetAuthState());
  // }
  return Promise.reject(error);
};
export default onError;
