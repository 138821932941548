import { FC } from 'react';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ['XLSX', 'CSV'];

interface IProps {
  handleChange: (value: any) => void;
  file: any;
}

export const DropFile: FC<IProps> = ({ handleChange, file }) => {
  return (
    <div className='wrapper-drop'>
      <FileUploader handleChange={handleChange} name='file' types={fileTypes} />
      <p className='wrapper-drop__name'>
        {file ? `File name: ${file?.name || ''}` : 'No files uploaded yet'}
      </p>
    </div>
  );
};
