import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as XLSX from 'xlsx';
import fileDownload from 'js-file-download';
import { t } from 'i18next';
import { showError } from '../../../utils/showError';
import { notificationContainer } from '../../../ui/notificationContainer';
import { clearErrors, loadingState } from './app-slice';
import RequestService from '../../../services/http/interceptor/request-service';
import { endpoint } from '../../../services/endpoint';
import { IRequestModify } from './app-types';
import { IXlsx } from 'pages/EditReport/EditReport';
import { ERoutes } from 'enum/types';

export const postUpload = createAsyncThunk('app/postUpload', async (payload: any, thunkApi) => {
  try {
    const response = await RequestService.post(endpoint.yotobe.POST_TABLE_UPLOAD, payload);
    notificationContainer('', 'success');
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
    }
  }
});

export const postModify = createAsyncThunk(
  'app/postModify',
  async (payload: IRequestModify, thunkApi) => {
    const { data, params } = payload;
    try {
      const response = await RequestService.post(
        endpoint.yotobe.POST_TABLE_MODIFY(params.session_id as string),
        data
      );
      const arr = response.data.data.data;
      thunkApi.dispatch(loadingState());
      thunkApi.dispatch(clearErrors());
      const newDataParse: Array<IXlsx> = await arr?.map((item: any) => {
        return {
          adjustment_type: item[0],
          asset_id: item[1],
          asset_title: item[2],
          asset_labels: item[3],
          asset_channel_id: item[4],
          asset_type: item[5],
          custom_id: item[6],
          isrc: item[7],
          upc: item[8],
          grid: item[9],
          artist: item[10],
          album: item[11],
          label: item[12],
          administer_publish_rights: item[13],
          owned_views: item[14],
          youTube_auction: item[15],
          youTube_reserved: item[16],
          youTube_served: item[17],
          youTube_partner_served: item[18],
          youTube_split: item[19],
          partner_auction: item[20],
          partner_reserved: item[21],
          partner_served: item[22],
          partner_partner_served: item[23],
          partner_revenue: item[24],
        };
      });
      thunkApi.dispatch(loadingState());
      return newDataParse;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0]);
      }
    }
  }
);

export const getFiles = createAsyncThunk('app/getFiles', async (payload: string, thunkApi) => {
  try {
    const response = await RequestService.get(endpoint.yotobe.GET_TABLE_FILES(payload));
    thunkApi.dispatch(clearErrors());
    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
      return thunkApi.rejectWithValue(error.response?.data.errors[0]);
    }
  }
});

export const getFile = createAsyncThunk('app/getFile', async (payload: any, thunkApi) => {
  const { session_id, filename } = payload;
  try {
    const response = await RequestService.getDownload(
      endpoint.yotobe.GET_TABLE_FILE(session_id, filename)
    );
    // fileDownload(response.data, filename);
    // const downloadAsFile = (f: any) => {
    //   console.log('click');
    //   const a = document.createElement('a');
    //   const file = new Blob([f], { type: 'text/csv' });
    //   a.href = URL.createObjectURL(file);
    //   a.download = `${filename}.csv`;
    //   a.click();
    // };

    // downloadAsFile(response.data);

    // console.log();
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
      return thunkApi.rejectWithValue(error.response?.data.errors[0]);
    }
  }
});

export const sendAws = createAsyncThunk('app/sendAws', async (payload: any, thunkApi) => {
  const { session_id, file, navigate } = payload;
  try {
    thunkApi.dispatch(loadingState());

    const response = await RequestService.post(endpoint.yotobe.SEND_AWS(session_id), file);
    thunkApi.dispatch(clearErrors());
    thunkApi.dispatch(loadingState());

    notificationContainer('', 'success');
    navigate(ERoutes.LIST_REPORT);
    return response.data;
  } catch (error) {
    thunkApi.dispatch(loadingState());

    if (isAxiosError(error)) {
      if (error.response?.data) {
        showError(error);
      }
      return thunkApi.rejectWithValue(error.response?.data.errors[0]);
    }
  }
});
